/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Matter from "matter-js";
import { useDispatch, useSelector } from "react-redux";
import { Player } from "./Player";
import { IGameStatusResponse, IWorldState } from "./IWorldState";
import { worldDimensions } from "../../configuration/WorldDimensions";
import { Ball } from "./Ball";
import { staticBodies } from "../../configuration/StaticBodies";
import { IState } from "../../state/IState";
import { completeGame, startGame } from "../../state/actions/gameStateActions";
import "./World.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { PlayersData } from "../../configuration/PlayerData";
import { ControlsEnum } from "../../enums/Controls";
import io, { Socket } from 'socket.io-client';
import { toast } from 'react-toastify';

const Engine = Matter.Engine,
  Render = Matter.Render,
  World = Matter.World;

var socket: Socket;
const PlayGround = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const gameId = queryParams.get('id');

  const [game, setGame] = useState<WagerType>();
  const [players, setPlayers] = useState<Player[]>([]);
  const [canControl, setCanControl] = useState(false);


  const gameState = useSelector((state: IState) => state);
  const dispatch = useDispatch();
  const worldRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<IWorldState>({ player1Points: 0, player2Points: 0 });

  const engine = Engine.create();
  const engineWorld = engine.world;

  const net = staticBodies.net;
  const ball = new Ball(15);

  const [secondsElapsed, setSecondsElapsed] = useState(0);


  useEffect(() => {
    socket = io(process.env.REACT_APP_BACKEND_URL as string, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionDelay: 3000,
    });
    if (game && players.length) {
      socket.on("connect", () => {
        console.log(socket.id, "socket id")
      })

      socket.on('disconnect', () => {
        // toast.info('connection lost');
      });

      socket.on(`game-${gameId}`, (data) => {
        if (data.type === 'control')
          setPositionAndScores(data.data);
      })

      const timerId = setInterval(() => {
        setSecondsElapsed(prevSeconds => prevSeconds + 1);
      }, 1000);

      return () => clearInterval(timerId);
    }

    if (game) {
      if (game.status === 'playing') {
        dispatch(startGame());
        setSecondsElapsed(Math.floor((new Date().getTime() - new Date(game.startedAt as string).getTime()) / 1000))
      }
      if (game.status === 'waitingForStart') {
        dispatch(startGame());
        setSecondsElapsed(0);
      }
      if (game.status === 'finished') {
        dispatch(game.result === 1 ? completeGame(game.player1.userName, game.player2.userName) : completeGame(game.player2.userName, game.player1.userName));
      }
    }

    return () => {
      socket.disconnect();
    }
  }, [game, players])

  useEffect(() => {
    if (gameState.profile.id === game?.player1.id || gameState.profile.id === game?.player2.id) {
      setCanControl(true);
    }
  }, [gameState.profile, game]);

  useEffect(() => {
    if (worldRef.current) {
      worldRef.current.focus();
    }
  });

  const isControlKey = (key: string) => {
    const controlKeys = [gameState.profile.keyDown, gameState.profile.keyUp, gameState.profile.keyLeft, gameState.profile.keyRight];
    return controlKeys.includes(key);
  }


  const setupWorld = () => {
    const render = Render.create({
      element: worldRef.current!,
      engine: engine,
      options: {
        width: worldDimensions.width,
        height: worldDimensions.height,
        wireframes: false,
        background: 'transparent',
      },
    });
    // engine.timing.timeScale = 1.2;
    const playerBodies = players.map(p => p.body);


    World.add(engineWorld, [
      ...playerBodies,
      staticBodies.ground,
      staticBodies.leftWall,
      staticBodies.rightWall,
      net,
      ball.body,
      ball.positionMarker
    ]);


    engineWorld.gravity.y = 1;
    // run the engine
    Engine.run(engine);

    // run the renderer
    Render.run(render);

    Matter.Events.on(engine, "beforeUpdate", event => {
      ball.setPositionMarker();
    });
  }

  const fetchGame = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/game/${gameId}`);
      setGame(response.data);

      const player1: UserType = response.data.player1;
      const player2: UserType = response.data.player2;
      const competitors: Player[] = [
        new Player(PlayersData[0].body, [
          { name: ControlsEnum.up, key: player1.keyUp },
          { name: ControlsEnum.down, key: player1.keyDown },
          { name: ControlsEnum.left, key: player1.keyLeft },
          { name: ControlsEnum.right, key: player1.keyRight }
        ], true, gameState.gameSettings.gameSpeed),
        new Player(PlayersData[1].body, [
          { name: ControlsEnum.up, key: player2.keyUp },
          { name: ControlsEnum.down, key: player2.keyDown },
          { name: ControlsEnum.left, key: player2.keyLeft },
          { name: ControlsEnum.right, key: player2.keyRight }
        ], false, gameState.gameSettings.gameSpeed)
      ];
      setPlayers(competitors);

    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    if (gameId) {
      fetchGame();
    }
  }, [gameId])

  const controlInfoRef = useRef({ canControl, gameId, gameState, isControlKey });

  // Update ref on each render
  controlInfoRef.current = { canControl, gameId, gameState, isControlKey };


  const keyUpHandler = useCallback((e: any) => {
    const { canControl, gameId, gameState, isControlKey } = controlInfoRef.current;
    e.preventDefault();
    if (!canControl || !isControlKey(e.key)) return;
    socket && socket.emit('game-control', {
      gameId: Number(gameId),
      userId: Number(gameState.profile.id),
      key: String(e.key),
      isPress: false
    });
  }, []);

  const keyDownHandler = useCallback((e: any) => {
    const { canControl, gameId, gameState, isControlKey } = controlInfoRef.current;
    e.preventDefault();
    if (!canControl || !isControlKey(e.key)) return;
    socket && socket.emit('game-control', {
      gameId: Number(gameId),
      userId: Number(gameState.profile.id),
      key: String(e.key),
      isPress: true
    });
  }, []);

  useEffect(() => {
    if (players.length)
      setupWorld();
  }, [players.length]);

  useEffect(() => {
    if (state.player1Points > 14 || state.player2Points > 14) {
      dispatch(
        state.player1Points > 14 ? completeGame(game?.player1.userName!, game?.player2.userName!) : completeGame(game?.player2.userName!, game?.player1.userName!)
      );
    }
  }, [state])

  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };


  const setPositionAndScores = (data: IGameStatusResponse) => {
    Matter.Body.setPosition(players[0].body, data.player1);
    Matter.Body.setPosition(players[1].body, data.player2);
    Matter.Body.setPosition(ball.body, data.ball);
    Matter.Body.setPosition(ball.positionMarker, data.positionMarker);

    const newState = { player1Points: data.player1Score, player2Points: data.player2Score };
    if (newState !== state) {
      setState(newState);
    }

  }

  return (
    <div className="World_container">

      <div className="gameScore">
        <div className="user">
          <div className="user-avatar" />
          <div className="user-name">
            {game?.player1.userName}
          </div>
        </div>
        <div className="score">{state.player1Points}</div>
        <div className="time">
          {formatTime(secondsElapsed)}
        </div>
        <div className="score">{state.player2Points}</div>
        <div className="user">
          <div className="user-avatar" />
          <div className="user-name">
            {game?.player2.userName}
          </div>
        </div>
      </div>
      <div
        className="World"
        tabIndex={-1}
        onKeyDown={keyDownHandler}
        onKeyUp={keyUpHandler}
        id="World"
        ref={worldRef}
      >
      </div>
    </div>
  );
}

export default PlayGround;
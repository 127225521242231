/* eslint-disable react-hooks/exhaustive-deps */
import "./App.scss";
import React, { useEffect, useRef, useState } from "react";
import GameOver from "./components/world/GameOver";
import Wager from "./components/wager/Wager";
import HowTo from "./components/how_to_play/HowTo";
import Profile from "./components/profile/Profile";
import Info from "./components/info/Info";
import Menu from "./components/menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "./state/IState";
import { GameStatus } from "./state/reducers/GameStateEnum";
import { Route, useLocation } from "react-router-dom";
import startBg from './data/images/start_bg.png';
import beachBg from './data/images/beach_bg.png';
import homeIcon from './data/images/Home.png';
import infoIcon from './data/images/info.png';
import walletIcon from './data/images/wallet.png';
import profileIcon from './data/images/Profile.png';
import { Link } from "react-router-dom";
import { setProvider, setPubkey } from "./state/actions/walletProviderActions";
import PlayGround from "./components/world/PlayGround";
import axios from "axios";
import { updateProfile } from "./state/actions/ProfileActions";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Buffer } from 'buffer';
window.Buffer = window.Buffer || Buffer;


const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const gameState = useSelector((state: IState) => state);
  const play = gameState.gameState.status === GameStatus.GAME_IS_OVER ? GameOver : PlayGround;
  const { pathname } = useLocation();
  const [isWalletConnect, setIsWalletConnect] = useState(false);

  const cursor = useRef<HTMLDivElement>(null);

  const changePosition = (e: React.MouseEvent<HTMLDivElement>) => {
    const customCursor = cursor.current;
    if (customCursor) {
      customCursor.style.top = `${e.clientY}px`;
      customCursor.style.left = `${e.clientX}px`;
    }
  }

  useEffect(() => {
    connectWallet();
  }, [])


  // useEffect(() => {
  //   switch (pathname) {
  //     case '/play':
  //     case '/fun':
  //       document.body.style.backgroundImage = `url(${beachBg})`;
  //       break;
  //     // Add more cases as needed for different routes
  //     default:
  //       document.body.style.backgroundImage = `url(${startBg})`;
  //   }
  // }, [pathname])

  useEffect(() => {
    if (gameState.walletProvider.pubkey !== "") {
      fetchUserInfo();
      setIsWalletConnect(true);
    } else {
      setIsWalletConnect(false);
    }
  }, [gameState.walletProvider.pubkey])

  const fetchUserInfo = async () => {
    try {
      const userInfo = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/wallet/${gameState.walletProvider.pubkey}`);
      if (userInfo.data) {
        dispatch(updateProfile(userInfo.data));
      } else {
        const newUser = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BACKEND_URL}/user/register`,
          data: { wallet: gameState.walletProvider.pubkey },
        });
        dispatch(updateProfile(newUser.data));
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }
    toast.info("Please install phantom wallet");
    window.open('https://phantom.app/', '_blank');
  };

  const connectWallet = async () => {
    try {
      const provider = getProvider();
      dispatch(setProvider(provider));
      try {
        const resp = await provider.connect();
        const wallet = resp.publicKey.toString();
        dispatch(setPubkey(wallet));
        setIsWalletConnect(true);

      } catch (err) {
        // toast.error("User rejected the connect request.");
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const isWalletConnected = async () => {
    if (isWalletConnect) {
      history.push("/profile");
    } else {
      connectWallet();
    }
  };

  return (
    <div className="App" style={{ minHeight: "100vh", minWidth: "100vw" }} onMouseMove={changePosition}>
      {/* <div className="cursor-style" ref={cursor}></div>
      <div className="header">
        <div className="header_menu">
          <Link to="/">
            <button className="button">
              <img src={homeIcon} alt="homeIcon" />
            </button>
          </Link>
          <Link to="/info">
            <button className="button" ><img src={infoIcon} alt="infoIcon" /></button>
          </Link>
          <button className="button"
            onClick={isWalletConnected}>
            <img src={!isWalletConnect ? walletIcon : profileIcon} alt="walletIcon" />
          </button>
        </div>
      </div>
      <ToastContainer />
      <Route exact path="/" component={Menu} />
      <Route exact path="/wager" component={Wager} />
      <Route path="/fun" component={Wager} />
      <Route path="/play" component={play} />
      <Route path="/how-to-play" component={HowTo} />
      <Route path="/info" component={Info} />
      <Route path="/profile" component={Profile} /> */}
    </div >
  );
};

export default App;
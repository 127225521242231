import { IPublicKeyAction, IWalletProviderAction } from './IWalletProviderActions';

export const setProvider = (provider: any): IWalletProviderAction => {
  return {
    type: "SetPhantomProvider",
    payload: provider
  }
}


export const setPubkey = (pubkey: string): IPublicKeyAction => {
  return {
    type: "SetWalletAddress",
    payload: pubkey
  }
}

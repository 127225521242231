import gameStatusReducer from "./gameStateReducer";
import playerDataReducer from "./playerDataReducer";
import { gameSettingsReducer } from "./gameSettingsReducer";
import { combineReducers } from "redux";
import walletProviderReducer from "./walletReducer";
import userProfileReducer from "./profileReducer";

const rootReducer = combineReducers({
  gameState: gameStatusReducer,
  playerData: playerDataReducer,
  gameSettings: gameSettingsReducer,
  walletProvider: walletProviderReducer,
  profile: userProfileReducer
});

export default rootReducer;

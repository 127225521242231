import { IProfileAction } from "../actions/IProfileAction";

const initialState: UserType = {
  id: 0,
  userName: "",
  wallet: "",
  keyUp: "ArrowUp",
  keyDown: "ArrowDown",
  keyRight: "ArrowRight",
  keyLeft: "ArrowLeft",
  credits: 0,
  experience: 0,
  level: 0,
  joinedAt: Date(),
}


const userProfileReducer = (
  state = initialState,
  action: IProfileAction
) => {
  switch (action.type) { // Check the action type
    case 'UPDATE_USER_PROFILE':
      return { ...state, ...action.payload };
    default:
      // If the action type is not recognized, return the current state
      return state;
  }
};

export default userProfileReducer;

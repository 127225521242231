import { IPlayerData } from "../interfaces/IPlayerData";
import { ControlsEnum } from "../enums/Controls"
import { worldDimensions } from "./WorldDimensions";
import palyer_image from '../data/images/player.png'

const playerOptions = { friction: 0, mass: 80, inertia: Infinity };

const PlayersData: Array<IPlayerData> = [
  {
    name: "player1",
    isLeftPlayer: true,
    controls: [
      { name: ControlsEnum.up, key: "w" },
      { name: ControlsEnum.down, key: "s" },
      { name: ControlsEnum.left, key: "a" },
      { name: ControlsEnum.right, key: "d" }
    ],
    body: {
      x: worldDimensions.width / 4,
      y: worldDimensions.height - worldDimensions.groundHeight - worldDimensions.circlesDiameter,

      diameter: worldDimensions.circlesDiameter, // fix player size according to the requirements
      options: {
        ...playerOptions, render: {
          // fillStyle: "#d8115a"
          sprite: {
            xScale: 0.2,
            yScale: 0.2,
            texture: palyer_image
          }
        }
      }
    }
  },
  {
    name: "player2",
    isLeftPlayer: false,
    controls: [
      { name: ControlsEnum.up, key: "ArrowUp" },
      { name: ControlsEnum.down, key: "ArrowDown" },
      { name: ControlsEnum.left, key: "ArrowLeft" },
      { name: ControlsEnum.right, key: "ArrowRight" }
    ],
    body: {
      x: (worldDimensions.width / 4) * 3,
      y: worldDimensions.height - worldDimensions.groundHeight - worldDimensions.circlesDiameter,
      diameter: worldDimensions.circlesDiameter,// fix player size according to the requirements
      options: {
        ...playerOptions, render: {
          // fillStyle: "#4696ff"
          sprite: {
            xScale: 0.2,
            yScale: 0.2,
            texture: palyer_image
          }
        }
      }
    }
  }
];
export { PlayersData };

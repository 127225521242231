import { worldDimensions } from "./WorldDimensions";
import Matter from "matter-js";

const staticBodies = {
  leftWall: Matter.Bodies.rectangle(
    worldDimensions.groundHeight / 2,
    worldDimensions.height / 2,
    worldDimensions.groundHeight,
    worldDimensions.height * 10,
    {
      isStatic: true,
      render: {
        fillStyle: 'white',
      }
    }
  ),
  rightWall: Matter.Bodies.rectangle(
    worldDimensions.width - worldDimensions.groundHeight / 2,
    worldDimensions.height / 2,
    worldDimensions.groundHeight,
    worldDimensions.height * 10,
    {
      isStatic: true,
      render: {
        fillStyle: 'white',
      }
    }

  ),
  ground: Matter.Bodies.rectangle(
    worldDimensions.width / 2,
    worldDimensions.height - worldDimensions.groundHeight / 2,
    worldDimensions.width,
    worldDimensions.groundHeight,
    {
      isStatic: true,
      render: {
        fillStyle: 'white',
      }
    }
  ),
  net: Matter.Bodies.rectangle(
    worldDimensions.width / 2 - 5,
    (worldDimensions.height / 4) * 3 - worldDimensions.groundHeight,
    20,
    worldDimensions.height / 2,
    {
      isStatic: true,
      render: {
        fillStyle: '#8C6755',
        strokeStyle: 'black'
      }
    }
  )
};

export { staticBodies };

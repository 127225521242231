import { ConnectedStatus } from "../../enums/ConnectedStatus";
import { IWalletProvider } from "../../interfaces/IWalletProvider";
import { IWalletProviderAction, IPublicKeyAction } from "../actions/IWalletProviderActions";

type Action = IWalletProviderAction | IPublicKeyAction;
const walletProviderReducer = (
  state = {
    status: ConnectedStatus.DISCONNECTED,
    provider: undefined,
    pubkey: "",
  },
  action: Action
) => {
  switch (action.type) {
    case "SetPhantomProvider":
      return setProvider(state, action as IWalletProviderAction);
    case "SetWalletAddress":
      return setPubkey(state, action as IPublicKeyAction)
    default:
      return state;
  }
};

function setProvider(state: IWalletProvider, action: IWalletProviderAction) {
  return {
    ...state,
    provider: action.payload,
    status: ConnectedStatus.CONNECTED,
  };
}

function setPubkey(state: IWalletProvider, action: IPublicKeyAction) {
  return {
    ...state,
    pubkey: action.payload,
  };
}

export default walletProviderReducer;
